import React, { useEffect, useState, useRef, useReducer, startTransition } from 'react';
import { ProductInterface } from '../../../../Interfaces/Product';
import Product from './Product';
import classNames from 'classnames';
import { ProductLayoutStyled, ProductsListLayout, TitleSection, BreadcrumbsContainer } from '../../../Styled/ProductLayoutStyled';
import { useDeviceDetect } from '../../../../LoadModules/DeviceDetect';
import { useSharedTranslation } from 'Translation/useSharedTranslation';
import LanguageSwitcher from 'Translation/LanguageSwitcher';
import FilterTaxon from '../Filters/FilterWithTaxon';
import { DropdownItem, FilterObject, ObjectItem } from '../../../../Interfaces/Filters';
import FilterWithObject from '../Filters/FilterWithObject';
import { faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import useProductFilters from '../../../../Hooks/useProductFilters';
import { useFetchTaxons } from '../../../../Services/FeatchTaxon';
import { Container } from '../../../../Components/Styled/CarouselBaseStyles';
import {
    ButtonFilter,
    RightSidebar,
    SideBarTitleContainer,
    Title,
    IconSetting,
    ButtonBackFilter,
    BarHr,
    FiltersContainer,
    FilterTitle,
    FilterTag,
    IconWrapper,
    CircleIcon,
    TimesIcon,
    ResetButton,
    FilterComponent,
    Overlay,
    SectionFilter,
    ButtonSeeAll,
    DeleteAll,
    ButtonContainer,
    SectionFilterContainer,
    SideBarHeaderContainer,
} from '../../../../Components/Styled/FilterStyle';
import { productsService, filterService } from '../../../../Services/Services';
import ProductRoutePrefixProvider, { useProductRoutePrefix } from '../../../../Context/ProductRoutePrefixContext';
import { useFetch } from '../../../../Hooks/useFetch';
import { useBasePrefix } from '../../../../Context/BasePrefixContext';
import Loader from '../../../../LoadModules/Loader';
import Breadcrumbs from '../../../../LoadModules/Breadcrumbs';

const ITEMS_PER_PAGE = 20;

const filterObjects = [
    { name: 'Physique', value: 'isPhysical' },
    { name: 'Virtuel', value: 'isVirtuel' },
];

const filterConditions = [
    { name: 'Cumulables', value: 'isCumulables' },
    { name: 'Sécables', value: 'isSécables' },
];

const filterGeographical: { name: string; value: string }[] = [];

const countryCodeToName: { [key: string]: string } = {
    FR: 'France',
    GR: 'Germany',
    BE: 'Belgium',
    RE: 'Reunion',
    PF: 'French Polynesia',
    GP: 'Guadeloupe',
};

const ActionTypes = {
    ADD_ITEM: 'ADD_ITEM',
    REMOVE_ITEM: 'REMOVE_ITEM',
    RESET_FILTERS: 'RESET_FILTERS',
};

const filterReducer = (state: string[], action: { type: string; key?: string }) => {
    switch (action.type) {
        case 'ADD_ITEM':
            if (action.key !== undefined && !state.includes(action.key)) {
                return [...state, action.key];
            }
            return state;
        case 'REMOVE_ITEM':
            return state.filter(item => item !== action.key);
        case 'RESET_FILTERS':
            return [];
        default:
            return state;
    }
};

interface ProductsProps {
    taxonCode?: string;
}

export const Products: React.FC<ProductsProps> = ({ taxonCode }) => {
    console.log('hello');

    const { t } = useSharedTranslation();
    const prefix = useProductRoutePrefix();
    const [products, setProducts] = useState<ProductInterface[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [displayOne, setDisplayOne] = useState(true);
    const productRefs = useRef<(HTMLDivElement | null)[]>([]);
    const [isLoadingAll, setIsLoadingAll] = useState(false);
    const [code, dispatchCode] = useReducer(filterReducer, []);
    const [itemsNames, setItemsNames] = useState<ObjectItem[]>([]);
    const [hidden, setHidden] = useState<boolean>(true);
    const [hiddenItems, setHiddenItems] = useState(new Set());
    const [showFilters, setShowFilters] = useState(false);
    const rightSidebarRef = useRef<HTMLDivElement | null>(null);
    const sectionRef = useRef<HTMLDivElement | null>(null);
    const [isLoadingSeeMore, setIsLoadingSeeMore] = useState(false);

    const [outSideSection, setOutSideSection] = useState(false);
    const { isMobile } = useDeviceDetect();

    const { data: productData, isLoading } = useFetch<{ products: ProductInterface[]; totalItems: number }, [string[], number]>({
        service: () => new productsService().getAllProducts(code, currentPage, ITEMS_PER_PAGE),
        cacheKey: `products_${code.join('_')}_${currentPage}_${ITEMS_PER_PAGE}`,
        dependencies: [code, currentPage],
    });

    const newProducts = productData?.products || [];
    const totalItems = productData?.totalItems || 0;

    const { data: filterData } = useFetch({
        service: () => new filterService().getFilterItem(),
        cacheKey: 'filters',
    });

    const [filteredProducts, setFilteredProducts] = useState<ProductInterface[] | undefined>(newProducts);
    const [filterItemsObject, setFilterItemsObject] = useState<FilterObject[]>([]);
    const [filterItemsGeographical, setFilterItemsGeographical] = useState<FilterObject[]>([]);
    const [filterItemsConditionObject, setFilterItemsConditionObjectt] = useState<FilterObject[]>([]);

    const { physicalProducts } = useProductFilters(
        filteredProducts,
        newProducts,
        filterData || undefined,
        code,
        filterItemsObject,
        filterItemsConditionObject,
        filterItemsGeographical,
    );

    const basePrefix = useBasePrefix();
    const [itemsToDeselect, setItemsToDeselect] = useState<string[] | string>([]);
    const [productsReset, setProductsReset] = useState(false);

    const containsFilterValue = code.some(code => filterObjects.some(filterObject => filterObject.value === code));
    const containsFilterConditionValue = code.some(code => filterConditions.some(filterConditions => filterConditions.value === code));
    const uniqueCountries = [
        ...new Set(products.flatMap(product => product.availableCountries).filter((country): country is string => country !== undefined)),
    ];
    uniqueCountries.forEach(countryCode => {
        if (!filterGeographical.some(condition => condition.value === countryCode)) {
            const countryName = countryCodeToName[countryCode] || countryCode;
            filterGeographical.push({ name: countryName, value: countryCode });
        }
    });

    const breadcrumbItems = [
        { name: t('base.avantage.breadcrumbs.home'), url: `${basePrefix}` },
        { name: t('base.footer.quickAccess.link.boutique'), url: `${basePrefix}${prefix}/shop` },
    ];

    useEffect(() => {
        setHidden(itemsNames.length === 0);
    }, [itemsNames]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (rightSidebarRef.current && !rightSidebarRef.current.contains(event.target as Node)) {
                setShowFilters(false);
            }
        }

        if (showFilters) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showFilters]);

    useEffect(() => {
        function handleClickOutsideSection(event: MouseEvent) {
            if (sectionRef.current && !sectionRef.current.contains(event.target as Node)) {
                setOutSideSection(true);
            } else {
                setOutSideSection(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutsideSection);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideSection);
        };
    }, []);

    useEffect(() => {
        const filteredProducts = physicalProducts && physicalProducts.length > 0 ? physicalProducts : newProducts;
        if (containsFilterValue || containsFilterConditionValue) {
            setFilteredProducts(physicalProducts);
        } else {
            setFilteredProducts(filteredProducts);
        }
    }, [products, physicalProducts, newProducts, code]);

    useEffect(() => {
        const handleScroll = () => {
            const newMiddleProductIds: number[] = [];
            const viewportHeight = window.innerHeight;
            const middleOfScreen = viewportHeight / 2;

            productRefs.current.forEach((ref, index) => {
                if (ref && products && products[index]) {
                    const { top, bottom } = ref.getBoundingClientRect();
                    const middleOfElement = (top + bottom) / 2;

                    if (middleOfElement > middleOfScreen - 100 && middleOfElement < middleOfScreen + 100) {
                        newMiddleProductIds.push(products[index].id as never);
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => window.removeEventListener('scroll', handleScroll);
    }, [products, code]);

    useEffect(() => {
        if (newProducts && newProducts.length > 0) {
            setProducts(prevProducts => [
                ...prevProducts,
                ...newProducts.filter(newProduct => !prevProducts.some(prevProduct => prevProduct.id === newProduct.id)),
            ]);
        }
    }, [newProducts, code]);

    useEffect(() => {
        if (isLoadingAll && currentPage < Math.ceil(totalItems! / ITEMS_PER_PAGE)) {
            setCurrentPage(currentPage + 1);
        }
    }, [currentPage, isLoadingAll, totalItems]);

    const handleLoadMore = (event: { preventDefault: () => void }) => {
        event.preventDefault();
        setIsLoadingSeeMore(true);
        setCurrentPage(prevPage => prevPage + 1);
    };

    const handleLoadAll = (event: { preventDefault: () => void }) => {
        setShowFilters(false);

        event.preventDefault();
        setIsLoadingAll(true);

        if (currentPage < Math.ceil(totalItems! / ITEMS_PER_PAGE)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleFilterSelect = (selectedItems: DropdownItem[], key: string | null | undefined, filterObjectVariable?: FilterObject[]) => {
        if (currentPage > 1) {
            setProducts([]);
            setCurrentPage(1);
        }
        setIsLoadingAll(false);
        if (!key) return;

        const isKeyObjectExist =
            filterItemsObject?.some(filterObj => filterObj.value === key) || filterItemsConditionObject?.some(filterObj => filterObj.value === key);

        const filterKey =
            filterItemsObject?.find(obj => code.includes(obj.value)) || filterItemsConditionObject?.find(obj => code.includes(obj.value));
        const result = code.length === 1 && filterObjectVariable?.some(filter => filter.value === code[0]);
        const index = code.findIndex(item => filterObjectVariable?.some(filter => filter.value === item));
        const allFilterValues = [...filterObjects.map(obj => obj.value), ...filterConditions.map(cond => cond.value)];
        if (!filterKey) {
            if (code.includes(key)) {
                dispatchCode({ type: ActionTypes.REMOVE_ITEM, key });
            } else {
                dispatchCode({ type: ActionTypes.ADD_ITEM, key });
            }
        } else {
            if (isKeyObjectExist) {
                if (result && !code.includes(key)) {
                    dispatchCode({ type: ActionTypes.REMOVE_ITEM, key: filterKey.value });
                    dispatchCode({ type: ActionTypes.ADD_ITEM, key });
                    setFilteredProducts(products);
                } else if (result && code.includes(key)) {
                    dispatchCode({ type: ActionTypes.RESET_FILTERS });
                } else if (index === -1) {
                    dispatchCode({ type: ActionTypes.REMOVE_ITEM, key: code[index] });
                    dispatchCode({ type: ActionTypes.ADD_ITEM, key });

                    if (code.every(value => allFilterValues.includes(value))) {
                        setFilteredProducts(products);
                    } else {
                        setFilteredProducts(newProducts);
                    }
                } else if (index != -1 && code.includes(key)) {
                    const element = code.find(item => item !== key);

                    dispatchCode({ type: ActionTypes.RESET_FILTERS });

                    setTimeout(() => {
                        dispatchCode({ type: ActionTypes.ADD_ITEM, key: element });
                    }, 0);
                } else if (index != -1 && !code.includes(key)) {
                    dispatchCode({ type: ActionTypes.REMOVE_ITEM, key: code[index] });

                    dispatchCode({ type: ActionTypes.ADD_ITEM, key: key });

                    if (code.every(value => allFilterValues.includes(value))) {
                        setFilteredProducts(products);
                    } else {
                        setFilteredProducts(newProducts);
                    }
                }
            } else {
                if (!code.includes(key)) {
                    dispatchCode({ type: ActionTypes.ADD_ITEM, key });
                } else if (index != -1 && code.includes(key) && code.length > 2) {
                    dispatchCode({ type: ActionTypes.REMOVE_ITEM, key: key });
                    setProducts([]);
                } else if (index != -1 && code.includes(key) && code.length > 1) {
                    dispatchCode({ type: ActionTypes.RESET_FILTERS });

                    setTimeout(() => {
                        dispatchCode({ type: ActionTypes.ADD_ITEM, key: code[1] });
                    }, 0);
                } else {
                    dispatchCode({ type: ActionTypes.REMOVE_ITEM, key: key });
                    setFilteredProducts(products);
                }
            }
        }
        updateItemsNames(selectedItems, key, filterKey, isKeyObjectExist);
    };

    const updateItemsNames = (
        selectedItems: DropdownItem[],
        key: string,
        filterKey: FilterObject | undefined,
        isKeyObjectExist: boolean | undefined,
    ) => {
        setItemsNames(prevItemsNames => {
            setHidden(false);
            const existingKeys = new Set(prevItemsNames.map(item => item.value));
            if (existingKeys.has(key)) {
                return prevItemsNames.filter(item => item.value !== key);
            } else if (filterKey && existingKeys.has(filterKey.value) && isKeyObjectExist) {
                const updatedNames = prevItemsNames.filter(item => item.value !== filterKey.value);
                const newItem = selectedItems.find(item => item.value === key);
                if (newItem) {
                    return [...updatedNames, newItem as ObjectItem];
                }
            } else {
                const newItem = selectedItems.find(item => item.value === key);
                if (newItem) {
                    return [...prevItemsNames, newItem as ObjectItem];
                }
            }
            return prevItemsNames;
        });
    };

    const handleRemoveTag = (itemToRemove: ObjectItem) => {
        setHiddenItems(prev => new Set(prev).add(itemToRemove.value));
        setTimeout(() => {
            setItemsNames(prevItemsNames => prevItemsNames.filter(item => item !== itemToRemove));

            setHiddenItems(prev => {
                const newSet = new Set(prev);
                newSet.delete(itemToRemove.value);
                return newSet;
            });
        }, 500);
        setItemsToDeselect([itemToRemove.value]);
        dispatchCode({ type: ActionTypes.REMOVE_ITEM, key: itemToRemove.value });

        setTimeout(() => {
            setItemsToDeselect([]);
        }, 1000);
    };

    const handleReset = () => {
        setShowFilters(false);
        setItemsToDeselect('All');

        setHidden(true);

        setTimeout(() => {
            setItemsNames([]);
        }, 500);
        dispatchCode({ type: ActionTypes.RESET_FILTERS });

        setTimeout(() => {
            setItemsToDeselect([]);
        }, 1000);
    };

    const [filterComponent, setFilterComponent] = useState<React.ReactNode | null>(null);
    const taxons_MENU_CATEGORY_SHOP = useFetchTaxons('MENU_CATEGORY_SHOP');
    const taxons_MENU_THEMES = useFetchTaxons('MENU_THEMES');

    useEffect(() => {
        if (taxonCode && taxons_MENU_CATEGORY_SHOP.taxons && taxons_MENU_THEMES.taxons) {
            if (code.length > 0) {
                dispatchCode({ type: ActionTypes.RESET_FILTERS });
            }
            if (itemsNames.length > 0) {
                setItemsNames([]);
            }
            dispatchCode({ type: ActionTypes.ADD_ITEM, key: taxonCode });

            const allTaxons = [...taxons_MENU_CATEGORY_SHOP.taxons, ...taxons_MENU_THEMES.taxons];
            const taxon = allTaxons.find(t => t.code === taxonCode);

            if (taxon) {
                const newItem: ObjectItem = {
                    physical: null,
                    name: taxon.name,
                    value: taxonCode,
                    title: taxon.name ?? 'No Title',
                };

                setItemsNames(prevItems => [...prevItems, newItem]);
            } else {
                setFilteredProducts(products);
                setFilterItemsGeographical(filterGeographical);
                if (products.length === 0) {
                    dispatchCode({ type: ActionTypes.RESET_FILTERS });
                    setProductsReset(prev => !prev);
                }

                const geoItem = filterItemsGeographical.find(item => item.value === taxonCode);
                if (geoItem) {
                    const newItem: ObjectItem = {
                        physical: null,
                        name: geoItem.name,
                        value: geoItem.value,
                        title: geoItem.name ?? 'No Title',
                    };

                    setItemsNames(prevItems => [...prevItems, newItem]);
                }
            }
        }
    }, [taxonCode, taxons_MENU_CATEGORY_SHOP.taxons, taxons_MENU_THEMES.taxons, filterItemsGeographical, productsReset]);

    type FilterType = 'SHOP' | 'THEMES' | 'TYPEOFPRODUCTS' | 'GEOLOCALISATION' | 'CONDITIONS';
    type OpenedFiltersState = {
        [key in FilterType]?: boolean;
    };

    const [openedFilters, setOpenedFilters] = useState<OpenedFiltersState>({});
    const [filterShowStates, setFilterShowStates] = useState<OpenedFiltersState>({});
    const filterContainerRef = useRef<HTMLDivElement>(null);

    const handleFilterClick = (filter: FilterType) => {
        setOpenedFilters(prevState => ({
            ...prevState,
            [filter]: !prevState[filter],
        }));
        setFilterShowStates(prevState => ({
            ...prevState,
            [filter]: !prevState[filter],
        }));
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (filterContainerRef.current && !filterContainerRef.current.contains(event.target as Node)) {
            setOpenedFilters({});
            setFilterShowStates({});
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const newFilterComponent = (
            <SectionFilterContainer ref={filterContainerRef}>
                <SectionFilter
                    ref={sectionRef}
                    className={classNames('columns', 'dropdown', {
                        'is-active': openedFilters.SHOP,
                        'section-filter-hide-dropdown': !openedFilters.SHOP,
                        'icon-not-rotated': openedFilters.SHOP,
                        'icon-rotated': !openedFilters.SHOP,
                    })}
                    onClick={() => handleFilterClick('SHOP')}
                >
                    <FilterTaxon
                        taxons={taxons_MENU_CATEGORY_SHOP.taxons}
                        title={taxons_MENU_CATEGORY_SHOP.title}
                        itemsToDeselect={itemsToDeselect}
                        showFilter={filterShowStates.SHOP || false}
                        onSelect={(selectedItems: DropdownItem[], _event?: React.MouseEvent<HTMLDivElement>, key?: string | null) => {
                            handleFilterSelect(selectedItems, key);
                        }}
                    />
                </SectionFilter>

                <SectionFilter
                    ref={sectionRef}
                    className={classNames('columns', 'dropdown', {
                        'is-active': openedFilters.THEMES,
                        'section-filter-hide-dropdown': !openedFilters.THEMES,
                        'icon-not-rotated': openedFilters.THEMES,
                        'icon-rotated': !openedFilters.THEMES,
                    })}
                    onClick={() => handleFilterClick('THEMES')}
                >
                    <FilterTaxon
                        taxons={taxons_MENU_THEMES.taxons}
                        title={taxons_MENU_THEMES.title}
                        itemsToDeselect={itemsToDeselect}
                        showFilter={filterShowStates.THEMES || false}
                        onSelect={(selectedItems: DropdownItem[], _event?: React.MouseEvent<HTMLDivElement>, key?: string | null) => {
                            handleFilterSelect(selectedItems, key);
                        }}
                    />
                </SectionFilter>

                <SectionFilter
                    ref={sectionRef}
                    className={classNames('columns', 'dropdown', {
                        'is-active': openedFilters.GEOLOCALISATION,
                        'section-filter-hide-dropdown': !openedFilters.GEOLOCALISATION,
                        'icon-not-rotated': openedFilters.GEOLOCALISATION,
                        'icon-rotated': !openedFilters.GEOLOCALISATION,
                    })}
                    onClick={() => handleFilterClick('GEOLOCALISATION')}
                >
                    <FilterWithObject
                        objects={filterGeographical}
                        title='Zone géographique'
                        itemsToDeselect={itemsToDeselect}
                        multiSelect={true}
                        showFilter={filterShowStates.GEOLOCALISATION || false}
                        onSelect={(selectedItems: DropdownItem[], _event?: React.MouseEvent<HTMLDivElement>, key?: string | null) => {
                            handleFilterSelect(selectedItems, key);
                        }}
                    />
                </SectionFilter>

                <SectionFilter
                    ref={sectionRef}
                    className={classNames('columns', 'dropdown', {
                        'is-active': openedFilters.TYPEOFPRODUCTS,
                        'section-filter-hide-dropdown': !openedFilters.TYPEOFPRODUCTS,
                        'icon-not-rotated': openedFilters.TYPEOFPRODUCTS,
                        'icon-rotated': !openedFilters.TYPEOFPRODUCTS,
                    })}
                    onClick={() => handleFilterClick('TYPEOFPRODUCTS')}
                >
                    <FilterWithObject
                        objects={filterObjects}
                        title='Type de produits'
                        itemsToDeselect={itemsToDeselect}
                        showFilter={filterShowStates.TYPEOFPRODUCTS || false}
                        multiSelect={false}
                        onSelect={(selectedItems: DropdownItem[], _event?: React.MouseEvent<HTMLDivElement>, key?: string | null) => {
                            setFilterItemsObject(filterObjects);
                            handleFilterSelect(selectedItems, key, filterObjects);
                        }}
                    />
                </SectionFilter>

                <SectionFilter
                    ref={sectionRef}
                    className={classNames('columns', 'dropdown', {
                        'is-active': openedFilters.CONDITIONS,
                        'section-filter-hide-dropdown': !openedFilters.CONDITIONS,
                        'icon-not-rotated': openedFilters.CONDITIONS,
                        'icon-rotated': !openedFilters.CONDITIONS,
                    })}
                    onClick={() => handleFilterClick('CONDITIONS')}
                >
                    <FilterWithObject
                        objects={filterConditions}
                        title='Conditions'
                        multiSelect={false}
                        itemsToDeselect={itemsToDeselect}
                        showFilter={filterShowStates.CONDITIONS || false}
                        last={true}
                        onSelect={(selectedItems: DropdownItem[], _event?: React.MouseEvent<HTMLDivElement>, key?: string | null) => {
                            setFilterItemsConditionObjectt(filterConditions);
                            handleFilterSelect(selectedItems, key, filterConditions);
                        }}
                    />
                </SectionFilter>
            </SectionFilterContainer>
        );

        if (!isLoading && taxons_MENU_CATEGORY_SHOP.taxons && taxons_MENU_THEMES.taxons) {
            setFilterComponent(newFilterComponent);
        }
    }, [isLoading, itemsToDeselect, taxons_MENU_CATEGORY_SHOP, taxons_MENU_THEMES, code, outSideSection, openedFilters, filterShowStates]);

    const mobileBreadcrumbs = isMobile ? [breadcrumbItems[breadcrumbItems.length - 1]] : breadcrumbItems;

    return (
        <div>
            <ProductRoutePrefixProvider>
                <BreadcrumbsContainer className='is-flex is-justify-content-start pl-4'>
                    <Breadcrumbs
                        items={mobileBreadcrumbs}
                        showEllipsis={false}
                    ></Breadcrumbs>
                </BreadcrumbsContainer>
            </ProductRoutePrefixProvider>

            <TitleSection className='pl-4'> {t('base.footer.quickAccess.link.boutique')} </TitleSection>

            <Overlay $isOpen={showFilters} />
            <RightSidebar
                ref={rightSidebarRef}
                data-showfilters={showFilters}
            >
                <SideBarHeaderContainer>
                    <SideBarTitleContainer>
                        <Title>
                            {t('product.filter.filter')} <IconSetting />
                        </Title>
                        <ButtonBackFilter onClick={() => setShowFilters(false)} />
                    </SideBarTitleContainer>
                    <BarHr isFirst />
                </SideBarHeaderContainer>

                {filterComponent}
                {showFilters && (
                    <ButtonContainer>
                        <ButtonSeeAll onClick={e => handleLoadAll(e)}>
                            {t('product.filter.seeproducts')} {totalItems} {t('product.filter.products')}
                        </ButtonSeeAll>
                        <DeleteAll onClick={handleReset}>{t('product.filter.delete')}</DeleteAll>
                    </ButtonContainer>
                )}
            </RightSidebar>

            <ProductsListLayout>
                {isMobile && (
                    <ButtonFilter
                        onClick={() => {
                            startTransition(() => {
                                setShowFilters(true);
                            });
                        }}
                    />
                )}

                {!isMobile && <FilterComponent>{filterComponent}</FilterComponent>}

                <Container className='mt-6'>
                    <FiltersContainer className={hidden ? 'hidden' : ''}>
                        {!isMobile && <FilterTitle>{t('ui.filter.filtertitle')} :</FilterTitle>}
                        {itemsNames.length > 0 &&
                            itemsNames.map((item, index) => (
                                <FilterTag
                                    className={hiddenItems.has(item.value) ? 'hidden' : ''}
                                    key={index}
                                    onClick={() => handleRemoveTag(item)}
                                >
                                    {item.title}
                                    <IconWrapper>
                                        <CircleIcon icon={faCircle} />
                                        <TimesIcon icon={faTimes} />
                                    </IconWrapper>
                                </FilterTag>
                            ))}
                        <ResetButton onClick={handleReset}> {t('ui.filter.reset')}</ResetButton>
                    </FiltersContainer>
                </Container>
                <ProductLayoutStyled
                    data-padding
                    className='products-layout container is-fluid'
                >
                    <div className='products-container'>
                        <div className='grid-4-columns is-hidden-mobile'>
                            {(isLoadingSeeMore || isLoadingAll ? products : filteredProducts)?.map((product: ProductInterface) => (
                                <Product
                                    product={product}
                                    key={product.id}
                                    gridType='grid-4'
                                />
                            ))}
                        </div>
                        <div className='is-hidden-desktop is-hidden-tablet'>
                            <div className='mobile-view-modes'>
                                <div className='mobile-view-mode-tabs'>
                                    <div
                                        id='mobile-view-mode-1'
                                        className={classNames({ 'mobile-view-mode-active': displayOne })}
                                        onClick={() => {
                                            setDisplayOne(true);
                                            setCurrentPage(1);
                                        }}
                                    >
                                        <span>1</span>
                                    </div>
                                    <div
                                        id='mobile-view-mode-2'
                                        className={classNames({ 'mobile-view-mode-active': !displayOne })}
                                        onClick={() => {
                                            setDisplayOne(false);
                                            setCurrentPage(2);
                                        }}
                                    >
                                        <span>2</span>
                                    </div>
                                </div>
                            </div>
                            <div className='grid-4-columns'>
                                {(isLoadingSeeMore || isLoadingAll ? products : filteredProducts)?.map((product: ProductInterface) => (
                                    <Product
                                        product={product}
                                        key={product.id}
                                        gridType='grid-1'
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </ProductLayoutStyled>
                {isLoading ?
                    <div className='loader'>
                        <Loader />
                    </div>
                :   null}
                {!isLoadingAll && products.length < totalItems! && (
                    <>
                        <div className='is-hidden'>
                            <LanguageSwitcher />
                        </div>
                        <div
                            className='paging'
                            id='itemsPerPage'
                            onClick={e => handleLoadMore(e)}
                        >
                            {t('products.pagination.plus')} ( {products.length} / {totalItems} )
                        </div>
                        <div
                            className='paging'
                            id='allItems'
                            onClick={e => handleLoadAll(e)}
                        >
                            {t('products.pagination.tout')}
                        </div>
                    </>
                )}
            </ProductsListLayout>
        </div>
    );
};
