import { ProductVariantInterface } from '../../../Interfaces/Productvariant.ts';
import classNames from 'classnames';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { formatCurrency } from '../../../Utils/NumberFormat.ts';
import { isEmpty } from '../../../Utils/Object.ts';
import FidelityAdvantage from '../../Common/FidelityAdvantage.tsx';
import { ProductVariantOptionStyled, ProductVariantSelectorStyled } from './VariantSelectorStyle.tsx';

type propsVariantSelector = {
    variants: ProductVariantInterface[];
    setVariantSelected: Dispatch<SetStateAction<ProductVariantInterface>>;
    variantSelected: ProductVariantInterface;
    price: number | null;
};

export default function VariantSelector({ variants, setVariantSelected, variantSelected, price }: propsVariantSelector) {
    const [isOpened, setIsOpened] = useState(false);
    useEffect(() => {
        if (price && variants.length > 0) {
            const matchingVariant = variants.find(variant => variant.originalPrice === price);
            if (matchingVariant) {
                setVariantSelected(matchingVariant);
            }
        } else if (variants.length === 1) {
            setVariantSelected(variants[0]);
        } else if (variants) {
            const variantCheapest = variants
                .filter(variant => variant.onHand && variant.onHand > 0)
                .sort((variant, variant2) => variant.price - variant2.price)
                .at(0);
            if (variantCheapest) {
                setVariantSelected(variantCheapest);
            }
        }
    }, [price, variants, setVariantSelected]);

    //TODO maybe create hook to detect outside click
    document.body.addEventListener('click', () => {
        setIsOpened(false);
    });

    const displayVariant = (variant: ProductVariantInterface) => {
        return (
            <ProductVariantOptionStyled
                className='product-variant-option'
                onClick={() => {
                    setVariantSelected(variant);
                }}
                key={variant.id}
            >
                <div className='product-variant-option-price'>{formatCurrency(variant.originalPrice)}</div>
                <div className='product-variant-option-availability'>{displayAvailability(variant)}</div>
                <div className='product-variant-option-emrys-coins'>
                    {variant.emrysCoins && <FidelityAdvantage fidelityAdvantage={variant.emrysCoins} />}
                </div>
            </ProductVariantOptionStyled>
        );
    };

    const displayAvailability = (variant: ProductVariantInterface) => {
        if (variant.onHand > 0) {
            return <span className={'has-text-success'}>Disponible</span>;
        } else if (variant.preOrderable) {
            return <span className={'has-text-warning'}>Précommande</span>;
        } else {
            return <span className={'has-text-danger'}>Indisponible</span>;
        }
    };

    return (
        <>
            <ProductVariantSelectorStyled
                className={classNames('product-variant-selector', { opened: isOpened })}
                onClick={evt => {
                    evt.stopPropagation();
                    setIsOpened(!isOpened);
                }}
            >
                <div className='product-variant-selected-container'>
                    <div className='product-variant-selected'>
                        <div className={classNames('product-variant-placeholder', { 'is-hidden': !isEmpty(variantSelected) })}>
                            Sélectionner un montant
                        </div>
                        <div className={classNames('product-variant-selected-option', { 'is-hidden': isEmpty(variantSelected) })}>
                            {displayVariant(variantSelected)}
                        </div>
                    </div>

                    <div className='product-variant-arrow'>
                        <div className={classNames('product-variant-arrow-icon', { opened: isOpened })}> </div>
                    </div>
                </div>

                <div className={classNames('product-variant-options', { 'is-flex': isOpened })}>
                    {variants?.map(variant => displayVariant(variant))}
                </div>
            </ProductVariantSelectorStyled>
        </>
    );
}
