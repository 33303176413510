import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorHandling } from './ErrorHandling';

const UiQuantityInput = React.lazy(() => import('Ui/QuantityInput').catch(ErrorHandling));

interface QuantityInputProps {
    quantity: number;
    setQuantity: (quantity: number) => void;
    min: number;
    max?: number;
}

const QuantityInput: React.FC<QuantityInputProps> = ({ quantity, setQuantity, min, max }) => (
    <ErrorBoundary fallback={<></>}>
        <Suspense fallback={<div>Loading Quantity Input...</div>}>
            <UiQuantityInput
                quantity={quantity}
                setQuantity={setQuantity}
                min={min}
                max={max}
            />
        </Suspense>
    </ErrorBoundary>
);

export default QuantityInput;
