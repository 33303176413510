import { ProductInterface } from '../../../../Interfaces/Product';
import { useFetch } from '../../../../Hooks/useFetch';
import { isEmpty } from '../../../../Utils/Object';
import classNames from 'classnames';
import React, { useState } from 'react';
import DetailTab from '../Tabs/DetailTab';
import RefundTab from '../Tabs/RefundTab';
import AttributeTab from '../Tabs/AttributeTab';
import CountryTab from '../Tabs/CountryTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductTabsContainerStyled, TabsProductStyled } from '../../../Styled/ProductTabsContainerStyled';
import { ProductAttributeInterface } from '../../../../Interfaces/ProductAttribute';
import { productsService } from '../../../../Services/Services';
import Loader from '../../../../LoadModules/Loader';

type ProductProps = {
    product: ProductInterface;
};

export default function ProductInformationMobile({ product }: ProductProps) {
    const { data: productAttributes, isLoading: isLoadingAttributes } = useFetch<ProductAttributeInterface[], [string]>({
        service: () => new productsService().getProductAttributes(product.code),
        cacheKey: `productAttributes_${product.code}`,
        dependencies: [product.code],
    });

    const [selectedTab, setSelectedTab] = useState([] as string[]);

    if (isLoadingAttributes) {
        return <Loader />;
    }

    function handleSelectingTab(e: React.MouseEvent<HTMLLIElement, MouseEvent>) {
        const targetId = e.currentTarget.id;
        if (selectedTab.includes(targetId)) {
            setSelectedTab(selectedTab.filter(tab => tab !== targetId));
            return;
        }

        setSelectedTab(current => [...current, targetId]);
    }

    const displayTabIcon = (tab: string) => {
        if (selectedTab.includes(tab)) {
            return (
                <FontAwesomeIcon
                    className={'fa-icons'}
                    icon={'minus'}
                />
            );
        }

        return (
            <FontAwesomeIcon
                className={'fa-icons'}
                icon={'plus'}
            />
        );
    };

    return (
        <>
            <ProductTabsContainerStyled
                id={'product-tabs-container'}
                className={'container'}
            >
                <TabsProductStyled
                    id={'tabs-product'}
                    className={'tabs is-centered'}
                >
                    <ul>
                        <li
                            id={'tab-details'}
                            onClick={handleSelectingTab}
                            className={classNames({ 'is-deploy': selectedTab.includes('tab-details') })}
                        >
                            <div className={'tab-menu-container'}>
                                <a>
                                    Informations
                                    {displayTabIcon('tab-details')}
                                </a>
                                <DetailTab
                                    product={product}
                                    isHidden={!selectedTab.includes('tab-details')}
                                />
                            </div>
                        </li>

                        {product.refundConditions && (
                            <li
                                id='tab-refund'
                                onClick={handleSelectingTab}
                                className={classNames({ 'is-deploy': selectedTab.includes('tab-refund') })}
                            >
                                <div className={'tab-menu-container'}>
                                    <a>
                                        Remboursement
                                        {displayTabIcon('tab-refund')}
                                    </a>
                                    <RefundTab
                                        product={product}
                                        isHidden={!selectedTab.includes('tab-refund')}
                                    />
                                </div>
                            </li>
                        )}

                        {!isEmpty(product.attributes) && productAttributes && (
                            <li
                                id='tab-attributes'
                                onClick={handleSelectingTab}
                                className={classNames({ 'is-deploy': selectedTab.includes('tab-attributes') })}
                            >
                                <div className={'tab-menu-container'}>
                                    <a>
                                        Attributs
                                        {displayTabIcon('tab-attributes')}
                                    </a>
                                    <AttributeTab
                                        productAttributes={productAttributes}
                                        isHidden={!selectedTab.includes('tab-attributes')}
                                    />
                                </div>
                            </li>
                        )}

                        {!isEmpty(product.availableCountries) && (
                            <li
                                id='tab-countries'
                                onClick={handleSelectingTab}
                                className={classNames({ 'is-deploy': selectedTab.includes('tab-countries') })}
                            >
                                <div className={'tab-menu-container'}>
                                    <a>
                                        Pays éligibles
                                        {displayTabIcon('tab-countries')}
                                    </a>
                                    <CountryTab
                                        countries={product.availableCountries}
                                        isHidden={!selectedTab.includes('tab-countries')}
                                    />
                                </div>
                            </li>
                        )}
                    </ul>
                </TabsProductStyled>
            </ProductTabsContainerStyled>
        </>
    );
}
