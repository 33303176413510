import { useLocation, useParams } from 'react-router-dom';
import Image from '../../Image/Image';
import { useFetch } from '../../../../Hooks/useFetch';
import VariantSelector from '../../VariantSelector/VariantSelector';
import { Suspense, useState } from 'react';
import { ProductVariantInterface } from '../../../../Interfaces/Productvariant';
import { isEmpty } from '../../../../Utils/Object';
import deliveryImageUrl from '@assets/icons/delivery.svg';
import ProductInformation from './ProductInformation';
import ProductInformationMobile from '../Mobile/ProductInformationMobile';
import ProductType from './ProductType';
import Flags from '../../Flags/Flags';
import Loader from 'Ui/Loader';
import { formatCurrency } from '../../../../Utils/NumberFormat';
import { useDeviceDetect } from '../../../../LoadModules/DeviceDetect';
import { ErrorBoundary } from 'react-error-boundary';
import { ProductLayoutStyled, BreadcrumbsContainer, ProductInformationContainerStyled, Title } from '../../../Styled/ProductLayoutStyled';
import { FlagsStyled } from '../../../Styled/FlagsStyled';
import { useSharedTranslation } from 'Translation/useSharedTranslation';
import { ProductRoutePrefixProvider, useProductRoutePrefix } from '../../../../Context/ProductRoutePrefixContext';
import { useBasePrefix } from '../../../../Context/BasePrefixContext';
import { ProductInterface } from '../../../../Interfaces/Product';
import { productsService, productVariantsService } from '../../../../Services/Services';
import Breadcrumbs from '../../../../LoadModules/Breadcrumbs';
import QuantityInput from '../../../../LoadModules/QuantityInput';
import AddToCart from '../../../../LoadModules/AddToCart';

export default function ProductDetail() {
    const { productCode } = useParams();
    const { data: productData, isLoading } = useFetch<{ product: ProductInterface; variants: ProductVariantInterface[] }, [string | undefined]>({
        service: async () => {
            const productService = new productsService();
            const product = await productService.getVariantsProductByCode(productCode || '');
            const variants = await new productVariantsService().getAllProductVariantsByProduct(product.id);
            return { product, variants };
        },
        cacheKey: `product_with_variants_${productCode}`,
        dependencies: [productCode],
    });

    const [variantSelected, setVariantSelected] = useState({} as ProductVariantInterface);
    const { isMobile } = useDeviceDetect();
    const [quantityToAdd, setQuantityToAdd] = useState(1);
    const { t } = useSharedTranslation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const priceStr = queryParams.get('price');
    const price = priceStr ? parseFloat(priceStr) : null;
    const prefix = useProductRoutePrefix();
    const basePrefix = useBasePrefix();

    if (isLoading || !productData) {
        return <Loader />;
    }

    const { product, variants } = productData;

    const displayDeliveryTime = (variant: ProductVariantInterface) => {
        if (variant.minDeliveryTime + variant.maxDeliveryTime === 0) {
            return <span>Immédiat</span>;
        } else if (variant.minDeliveryTime === variant.maxDeliveryTime) {
            return <span>{`${variant.minDeliveryTime} jours ouvrés`}</span>;
        } else {
            return <span>{`${variant.minDeliveryTime}/${variant.maxDeliveryTime} jours ouvrés`}</span>;
        }
    };
    const breadcrumbItems = [
        { name: t('base.avantage.breadcrumbs.home'), url: `${basePrefix}` },
        { name: t('base.footer.quickAccess.link.boutique'), url: `${basePrefix}${prefix}/shop` },
        { name: product.name, url: '/' + product.name },
    ];
    const mobileBreadcrumbs = isMobile ? [breadcrumbItems[breadcrumbItems.length - 1]] : breadcrumbItems;

    return (
        <>
            <Suspense fallback={<Loader />}>
                <ProductRoutePrefixProvider>
                    <BreadcrumbsContainer className='is-flex is-justify-content-start pl-4'>
                        <Breadcrumbs
                            items={mobileBreadcrumbs}
                            showEllipsis={false}
                        ></Breadcrumbs>
                    </BreadcrumbsContainer>
                </ProductRoutePrefixProvider>
                <ProductLayoutStyled className={'products-layout container is-fluid'}>
                    <div className={'container'}>
                        <div className={'columns'}>
                            <div className={'column has-text-centered'}>
                                <Image
                                    product={product}
                                    type={'sylius_large'}
                                    classNames={'img-product-details'}
                                />
                            </div>
                            <ProductInformationContainerStyled
                                className={'column'}
                                id={'product-information-container'}
                            >
                                <Title>{product.name}</Title>
                                <div className={'type-flags-container'}>
                                    <div className={'product-shop-type is-flex'}>
                                        <ProductType product={product} />
                                    </div>
                                    <FlagsStyled className='flags'>
                                        <Flags product={product} />
                                    </FlagsStyled>
                                </div>
                                {!isEmpty(variantSelected) && (
                                    <div className='delivery-time'>
                                        <img
                                            src={deliveryImageUrl}
                                            alt='delivery-time'
                                        />
                                        {displayDeliveryTime(variantSelected)}
                                    </div>
                                )}
                                <div className='product-variant'>
                                    {
                                        <VariantSelector
                                            variants={variants}
                                            variantSelected={variantSelected}
                                            setVariantSelected={setVariantSelected}
                                            price={price}
                                        />
                                    }
                                </div>
                                <div id='product-add-to-cart-container'>
                                    <div className='columns'>
                                        <div className='column is-one-third'>
                                            <QuantityInput
                                                quantity={quantityToAdd}
                                                setQuantity={setQuantityToAdd}
                                                min={1}
                                                max={100}
                                            ></QuantityInput>
                                        </div>
                                        <div className='column'>
                                            {/* FIXME: check if i should move this span */}
                                            <ErrorBoundary fallback={<span className={'is-size-7 has-text-weight-light'}>Pannier indisponible</span>}>
                                                <AddToCart
                                                    productVariantCode={isEmpty(variantSelected) ? '' : variantSelected.code}
                                                    quantity={quantityToAdd}
                                                    extraLabel={
                                                        isEmpty(variantSelected) ? '' : formatCurrency(quantityToAdd * variantSelected.originalPrice)
                                                    }
                                                    disabled={isEmpty(variantSelected)}
                                                    // onSuccess={() => alert('Added to cart')}
                                                    onFailure={(message: string) => {
                                                        alert(message);
                                                    }}
                                                />
                                            </ErrorBoundary>
                                        </div>
                                    </div>
                                </div>
                            </ProductInformationContainerStyled>
                        </div>
                        <Suspense fallback={<Loader />}>
                            {isMobile ?
                                <ProductInformationMobile product={product} />
                            :   <ProductInformation product={product} />}
                        </Suspense>
                    </div>
                </ProductLayoutStyled>
            </Suspense>
        </>
    );
}
