import React from 'react';
import { useDeviceDetect } from '../../../../LoadModules/DeviceDetect';
import { Taxon } from '../../../../Services/FeatchTaxon';
import { FilterTaxonsProps, DropdownItem, SelectProps } from '../../../../Interfaces/Filters';
import { BarHr, SelectDropDownStyle } from '../../../Styled/FilterStyle';
import SelectDropDown from '../../../../LoadModules/SelectDropDown';

const FilterTaxon: React.FC<FilterTaxonsProps & { onClick?: () => void; showFilter: boolean }> = ({
    taxons,
    title,
    itemsToDeselect,
    showFilter,
    onSelect,
}) => {
    const { isMobile } = useDeviceDetect();

    const selectProps: SelectProps = {
        dropdownTitle: title,
        items: taxons.map((taxon: Taxon) => ({
            type: 'item',
            title: taxon.name,
            value: taxon.code,
        })),
        multiSelect: true,
        closeOnOutsideClick: false,
        itemsToDeselect: itemsToDeselect,
        onSelect: (selectedItems: DropdownItem[], event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (event && event.target instanceof HTMLElement) {
                const key = event.target.getAttribute('data-key');

                if (key) {
                    onSelect(selectedItems, event, key);
                }
            }
        },
    };

    const numberOfItems = taxons.length;

    return (
        <SelectDropDownStyle className='column'>
            <SelectDropDown {...selectProps} />
            {isMobile && <BarHr $margintop={showFilter ? numberOfItems * 12 : 10} />}
        </SelectDropDownStyle>
    );
};

export default FilterTaxon;
