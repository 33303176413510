import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorHandling } from './ErrorHandling';

const LazyAddToCart = React.lazy(() => import('Cart/AddToCart').catch(ErrorHandling));

interface AddToCartProps {
    productVariantCode: string;
    quantity: number;
    disabled: boolean;
    extraLabel: string;
    onSuccess?: () => void;
    onFailure?: (message: string) => void;
}

const AddToCart: React.FC<AddToCartProps> = ({ productVariantCode, quantity, disabled, extraLabel, onSuccess, onFailure }) => (
    <ErrorBoundary fallback={<></>}>
        <Suspense fallback={<div>Loading Add To Cart...</div>}>
            <LazyAddToCart
                productVariantCode={productVariantCode}
                quantity={quantity}
                disabled={disabled}
                extraLabel={extraLabel}
                onSuccess={onSuccess}
                onFailure={onFailure}
            />
        </Suspense>
    </ErrorBoundary>
);

export default AddToCart;
