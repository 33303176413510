import { ProductInterface } from '../../../../Interfaces/Product.ts';
import classNames from 'classnames';

type RefundProps = {
    product: ProductInterface;
    isHidden: boolean;
};

export default function RefundTab({ product, isHidden }: RefundProps) {
    return (
        <>
            <div className={classNames('tab-menu-content tab-content product-information', { 'is-hidden': isHidden })}>
                <div
                    className={'product-description'}
                    dangerouslySetInnerHTML={{ __html: product.refundConditions ?? '' }}
                />
            </div>
        </>
    );
}
