import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorHandling } from './ErrorHandling';

interface BreadcrumbItem {
    name: string;
    url?: string;
}

interface BreadcrumbProps {
    items: BreadcrumbItem[];
    showEllipsis?: boolean;
}

const UiBreadcrumbs = React.lazy(() => import('Ui/Breadcrumbs').catch(ErrorHandling));

const Breadcrumbs: React.FC<BreadcrumbProps> = ({ items, showEllipsis = false }) => (
    <ErrorBoundary fallback={<></>}>
        <Suspense fallback={<div>Loading Breadrumbs...</div>}>
            <UiBreadcrumbs
                items={items}
                showEllipsis={showEllipsis}
            />
        </Suspense>
    </ErrorBoundary>
);

export default Breadcrumbs;
