import { Suspense } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import ProductDetail from './Components/Product/Layouts/Products/ProductDetail';
import Carousels from './Components/Product/Layouts/Carousels/Carousels';
import { Products } from './Components/Product/Layouts/Products/Products';
import Loader from './LoadModules/Loader';

const TaxonProducts = () => {
    const { taxonCode } = useParams();
    return <Products taxonCode={taxonCode} />;
};

const ProductRoutes = () => {
    return (
        <Routes>
            <Route path={`/`}>
                <Route
                    path={`taxons/:taxonCode`}
                    element={
                        <Suspense fallback={<Loader />}>
                            <TaxonProducts />
                        </Suspense>
                    }
                />
                <Route
                    path={`shop`}
                    element={
                        <Suspense fallback={<Loader />}>
                            <Products />
                        </Suspense>
                    }
                />
                <Route
                    path={`list`}
                    element={
                        <Suspense fallback={<Loader />}>
                            <Carousels />
                        </Suspense>
                    }
                />
                <Route
                    path={`:productCode`}
                    element={
                        <Suspense fallback={<Loader />}>
                            <ProductDetail />
                        </Suspense>
                    }
                />
            </Route>
        </Routes>
    );
};

const Router = () => {
    return (
        <Suspense fallback={<Loader />}>
            <ProductRoutes />
        </Suspense>
    );
};

export default Router;
