import { ProductInterface } from '../../../../Interfaces/Product';
import { useFetch } from '../../../../Hooks/useFetch';
import { isEmpty } from '../../../../Utils/Object';
import classNames from 'classnames';
import { useState } from 'react';
import DetailTab from './../Tabs/DetailTab';
import RefundTab from '../Tabs/RefundTab';
import AttributeTab from '../Tabs/AttributeTab';
import CountryTab from '../Tabs/CountryTab';
import { ProductTabsContainerStyled } from '../../../Styled/ProductTabsContainerStyled';
import { ProductAttributeInterface } from '../../../../Interfaces/ProductAttribute';
import { productsService } from '../../../../Services/Services';
import Loader from '../../../../LoadModules/Loader';

type ProductProps = {
    product: ProductInterface;
};

export default function ProductInformation({ product }: ProductProps) {
    const { data: productAttributes, isLoading: isLoadingAttributes } = useFetch<ProductAttributeInterface[], [string]>({
        service: () => new productsService().getProductAttributes(product.code),
        cacheKey: `productAttributes_${product.code}`,
        dependencies: [product.code],
    });

    const [selectedTab, setSelectedTab] = useState('tab-details');

    if (isLoadingAttributes) {
        return <Loader />;
    }

    return (
        <>
            <ProductTabsContainerStyled
                id={'product-tabs-container'}
                className={'container'}
            >
                <div
                    id={'tabs-product'}
                    className={'tabs is-centered'}
                >
                    <ul>
                        <li
                            id={'tab-details'}
                            onClick={e => setSelectedTab(e.currentTarget.id)}
                            className={classNames({ 'is-active': selectedTab === 'tab-details' })}
                        >
                            <div className={'tab-menu-container'}>
                                <a>Informations</a>
                                <div className={'tab-menu-content is-hidden-tablet is-hidden-desktop'}></div>
                            </div>
                        </li>
                        {product.refundConditions && (
                            <li
                                id='tab-refund'
                                onClick={e => setSelectedTab(e.currentTarget.id)}
                                className={classNames({ 'is-active': selectedTab === 'tab-refund' })}
                            >
                                <div className={'tab-menu-container'}>
                                    <a>Remboursement</a>
                                    <div className={'tab-menu-content is-hidden-tablet is-hidden-desktop'}></div>
                                </div>
                            </li>
                        )}
                        {!isEmpty(product.attributes) && (
                            <li
                                id='tab-attributes'
                                onClick={e => setSelectedTab(e.currentTarget.id)}
                                className={classNames({ 'is-active': selectedTab === 'tab-attributes' })}
                            >
                                <div className={'tab-menu-container'}>
                                    <a>Attributs</a>
                                    <div className={'tab-menu-content is-hidden-tablet is-hidden-desktop'}></div>
                                </div>
                            </li>
                        )}
                        {!isEmpty(product.availableCountries) && (
                            <li
                                id='tab-countries'
                                onClick={e => setSelectedTab(e.currentTarget.id)}
                                className={classNames({ 'is-active': selectedTab === 'tab-countries' })}
                            >
                                <div className={'tab-menu-container'}>
                                    <a>Pays éligibles</a>
                                    <div className={'tab-menu-content is-hidden-tablet is-hidden-desktop'}></div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>

                <DetailTab
                    product={product}
                    isHidden={selectedTab !== 'tab-details'}
                />
                <RefundTab
                    product={product}
                    isHidden={selectedTab !== 'tab-refund'}
                />
                {productAttributes && (
                    <AttributeTab
                        productAttributes={productAttributes}
                        isHidden={selectedTab !== 'tab-attributes'}
                    />
                )}
                <CountryTab
                    countries={product.availableCountries}
                    isHidden={selectedTab !== 'tab-countries'}
                />
            </ProductTabsContainerStyled>
        </>
    );
}
