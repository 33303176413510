import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorHandling } from './ErrorHandling';
import { SelectProps } from 'src/Interfaces/Filters';

const UiSelectDropDown = React.lazy(() => import('Ui/Select').catch(ErrorHandling));

const SelectDropDown: React.FC<SelectProps> = () => (
    <ErrorBoundary fallback={<></>}>
        <Suspense fallback={<div>Loading Select DropDown...</div>}>
            <UiSelectDropDown />
        </Suspense>
    </ErrorBoundary>
);

export default SelectDropDown;
