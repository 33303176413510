import styled from 'styled-components';

export const ProductVariantSelectorStyled = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    padding: 8px 12px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid ${props => props.theme.colors.greyLight};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1) inset;
    max-height: 46px;
    transition: max-height 0.5s;
    overflow: hidden;
    cursor: pointer;

    ${props => props.theme.devices.desktop} {
        width: 553px;
    }

    &.opened {
        max-height: 1000px;
        transition: max-height 0.5s;

        .product-variant-options {
            visibility: visible;
            opacity: 1;
        }
    }

    .product-variant-options {
        flex-direction: column;
        margin-top: 10px;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        transition:
            visibility 0.5s linear,
            opacity 0.5s linear;

        .product-variant-option {
            width: 100%;
            padding-bottom: 15px;
            padding-top: 15px;
        }

        .product-variant-option:not(:last-of-type) {
            border-bottom: none;

            ${props => props.theme.devices.desktop} {
                border-bottom: 1px ${props => props.theme.colors.greyLight} solid;
            }
        }
    }

    .product-variant-selected-container {
        display: flex;
        width: 100%;

        .product-variant-selected {
            display: flex;
            align-items: center;
            gap: 5px;
            align-self: stretch;
            width: 100%;

            .product-variant-placeholder {
                display: flex;
                width: 500px;
                align-items: center;
                align-self: stretch;
                justify-content: space-between;
                height: 30px;
            }

            .product-variant-selected-option {
                width: 100%;

                .product-variant-option {
                    height: 30px;
                }
            }
        }

        .product-variant-arrow {
            display: flex;
            width: 17px;
            height: ${props => props.theme.globalSize.size6};
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 12px;
            top: 12px;

            .product-variant-arrow-icon {
                width: 11px;
                height: 11px;
                transform: rotate(135deg);
                flex-shrink: 0;
                border-radius: 2px;
                border-top: 3px solid ${props => props.theme.colors.greyDark};
                border-right: 3px solid ${props => props.theme.colors.greyDark};
                transform-origin: center;
                transition: transform 0.3s linear;

                &.opened {
                    transform: rotate(-45deg);
                    transition: transform 0.3s linear;
                }
            }
        }
    }
`;

export const ProductVariantOptionStyled = styled.div`
    display: flex;
    align-items: center;
    align-self: stretch;
    cursor: pointer;
    width: 100%;

    ${props => props.theme.devices.desktop} {
        width: 500px;
        
    }

    .product-variant-option-availability {
        display: flex;
        flex: auto;
        justify-content: center;
    }

    .product-variant-option-price, .product-variant-option-emrys-coins {
        width: 85px;
    }

    .product-variant-option-price, .product-variant-option-availability {
        color: ${props => props.theme.colors.greyDark};
        font-family: ${props => props.theme.fonts.familyPrimary}
        font-size: ${props => props.theme.globalSize.size6};
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }

    .product-variant-option-emrys-coins {
        display: flex;
        align-items: center;
        gap: 5px;

        > img {
            width: 30px;
            height: 30px;
        }

        > span {
            color: ${props => props.theme.colors.greyDark};
            font-family: ${props => props.theme.fonts.familyPrimary}
            font-size: ${props => props.theme.globalSize.size7};
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
        }
    }
`;
