import React from 'react';
import { useDeviceDetect } from '../../../../LoadModules/DeviceDetect';
import { DropdownItem, SelectProps, FilterObjectsProps } from '../../../../Interfaces/Filters';
import { BarHr, SelectDropDownStyle } from '../../../Styled/FilterStyle';
import SelectDropDown from '../../../../LoadModules/SelectDropDown';

const FilterWithObject: React.FC<FilterObjectsProps & { onClick?: () => void; showFilter: boolean }> = ({
    objects,
    title,
    itemsToDeselect,
    showFilter,
    onSelect,
    last,
    multiSelect,
}) => {
    const { isMobile } = useDeviceDetect();

    const items: DropdownItem[] = objects.map((res: { name: string; value: string }) => ({
        type: 'item',
        title: res.name,
        value: res.value,
    }));

    const selectProps: SelectProps = {
        dropdownTitle: title,
        items: items,
        multiSelect: multiSelect,
        closeOnOutsideClick: true,
        itemsToDeselect: itemsToDeselect,
        onSelect: (selectedItems: DropdownItem[], event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (event && event.target instanceof HTMLElement) {
                const key = event.target.getAttribute('data-key');
                if (key) {
                    onSelect(selectedItems, event, key);
                }
            }
        },
    };

    return (
        <SelectDropDownStyle className='column'>
            <SelectDropDown {...selectProps} />
            {isMobile && !last && <BarHr $margintop={showFilter ? 36 : 10} />}
        </SelectDropDownStyle>
    );
};

export default FilterWithObject;
