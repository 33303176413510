import { useEffect, useRef, useState } from 'react';
import { ProductInterface } from '../../../../Interfaces/Product.ts';
import Image from '../../Image/Image.tsx';
import { Link } from 'react-router-dom';
import ProductType from './ProductType.tsx';
import Flags from '../../Flags/Flags.tsx';
import { FlagsStyled } from '../../../Styled/FlagsStyled.tsx';
import classNames from 'classnames';
import { useProductRoutePrefix } from '../../../../Context/ProductRoutePrefixContext.tsx';
import { useBasePrefix } from '../../../../Context/BasePrefixContext.tsx';

type GridType = 'grid-4' | 'grid-1' | 'grid-2';

type ProductProps = {
    product: ProductInterface;
    gridType?: GridType;
};

export default function Product({ product, gridType }: ProductProps) {
    const basePrefix = useBasePrefix();

    const priceMapContainerRef = useRef<HTMLDivElement>(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true);
    const [isScrollNeeded, setIsScrollNeeded] = useState(false);
    const [updateArrowVisibility, setUpdateArrowVisibility] = useState(() => () => {});
    const prefix = useProductRoutePrefix();

    function debounce<T extends (...args: unknown[]) => void>(func: T, timeout = 200): (...args: Parameters<T>) => void {
        let timer: ReturnType<typeof setTimeout> | null = null;
        return (...args: Parameters<T>) => {
            if (timer !== null) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => func(...args), timeout);
        };
    }
    useEffect(() => {
        const debouncedUpdate = debounce(() => {
            const container = priceMapContainerRef.current;
            if (container) {
                const isScrolledToStart = container.scrollLeft <= 0;
                const tolerance = 1;
                const isScrolledToEnd = container.scrollLeft + container.clientWidth + tolerance >= container.scrollWidth;
                setShowLeftArrow(!isScrolledToStart);
                setShowRightArrow(!isScrolledToEnd);
            }
        }, 200);

        setUpdateArrowVisibility(() => debouncedUpdate);
    }, []);

    useEffect(() => {
        updateArrowVisibility();
        window.addEventListener('resize', updateArrowVisibility);
        const container = priceMapContainerRef.current;
        container?.addEventListener('scroll', updateArrowVisibility);

        return () => {
            window.removeEventListener('resize', updateArrowVisibility);
            container?.removeEventListener('scroll', updateArrowVisibility);
        };
    }, [updateArrowVisibility]);

    useEffect(() => {
        const checkIfScrollIsNeeded = () => {
            if (priceMapContainerRef.current && product.variants) {
                const { scrollWidth, clientWidth } = priceMapContainerRef.current;
                const needsScroll = scrollWidth > clientWidth;

                switch (gridType) {
                    case 'grid-4':
                        setIsScrollNeeded(needsScroll && product.variants.length > 6);
                        break;
                    case 'grid-1':
                        setIsScrollNeeded(needsScroll && product.variants.length > 4);
                        break;
                    case 'grid-2':
                        setIsScrollNeeded(needsScroll && product.variants.length > 2);
                        break;
                    default:
                        setIsScrollNeeded(needsScroll);
                        break;
                }
            }
        };
        checkIfScrollIsNeeded();
        updateArrowVisibility();

        window.addEventListener('resize', checkIfScrollIsNeeded);
        return () => {
            window.removeEventListener('resize', checkIfScrollIsNeeded);
        };
    }, [updateArrowVisibility, product.variants, gridType]);

    const scrollAmount = 125;

    const scrollLeft = (): void => {
        if (priceMapContainerRef.current) {
            priceMapContainerRef.current.scrollTo({
                left: priceMapContainerRef.current.scrollLeft - scrollAmount,
                behavior: 'smooth',
            });
        }
    };

    const scrollRight = (): void => {
        if (priceMapContainerRef.current) {
            priceMapContainerRef.current.scrollTo({
                left: priceMapContainerRef.current.scrollLeft + scrollAmount,
                behavior: 'smooth',
            });
        }
    };

    function formatCurrency(price: number): string {
        const priceInEuros = price / 100;
        const isWholeNumber = priceInEuros % 1 === 0;

        return new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: isWholeNumber ? 0 : 2,
            maximumFractionDigits: 2,
        }).format(priceInEuros);
    }

    const leftArrowClasses = classNames('scroll-left-arrow', {
        'is-invisible': !showLeftArrow,
    });

    const rightArrowClasses = classNames('scroll-right-arrow', {
        'is-invisible': !showRightArrow,
    });

    const containerClasses = classNames('price-map-container', 'is-flex', {
        'is-justify-content-center':
            (gridType === 'grid-2' && product.variants && product.variants.length < 3) ||
            (gridType === 'grid-1' && product.variants && product.variants.length < 6) ||
            (gridType === 'grid-4' && product.variants && product.variants.length < 7),
    });

    return (
        <>
            <div className='product'>
                <div className='product-name'>
                    <span>{product?.name}</span>
                </div>
                <div className='product-price'>
                    <div
                        className={containerClasses}
                        ref={priceMapContainerRef}
                    >
                        {product.variants?.map((price, index) => (
                            <div key={index}>
                                <span className='price-container'>
                                    <Link to={`${basePrefix}${prefix}/${product.code}?price=${encodeURIComponent(price.price)}`}>
                                        {formatCurrency(price.price)}
                                    </Link>
                                </span>
                            </div>
                        ))}
                    </div>
                    {isScrollNeeded && (
                        <div className='arrow-container'>
                            <div
                                className={leftArrowClasses}
                                onClick={scrollLeft}
                            >
                                <div className='arrowLeft' />
                            </div>
                            <div
                                className={rightArrowClasses}
                                onClick={scrollRight}
                            >
                                <div className='arrowRight' />
                            </div>
                        </div>
                    )}
                </div>
                <div className='product-card'>
                    <div className='product-card-body'>
                        <div className='product-info'>
                            <div className='countries'>
                                <FlagsStyled className='flags'>
                                    <Flags product={product} />
                                </FlagsStyled>
                            </div>
                            <div className='product-type'>
                                <ProductType
                                    product={product}
                                    display
                                />
                            </div>
                        </div>
                        <div className='product-image'>
                            <Image
                                product={product}
                                type={'sylius_large'}
                            />
                        </div>
                    </div>
                </div>
                <Link
                    className={'product-link'}
                    to={`${basePrefix}${prefix}/${product.code}`}
                />
            </div>
        </>
    );
}
