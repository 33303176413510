import { ProductInterface } from '../../../Interfaces/Product.ts';
import ReactCountryFlag from 'react-country-flag';
import { isEmpty } from '../../../Utils/Object.ts';
import { useState } from 'react';

type FlagProps = {
    product: ProductInterface;
};

export default function Flags({ product }: FlagProps) {
    const codesDrom = ['GP', 'GF', 'MQ', 'YT', 'NC', 'PF', 'RE'];
    const [hasDrom, setHasDrom] = useState(false);

    const isDrom = (code: string) => codesDrom.includes(code);

    const displayFlag = (countryCode: string) => {
        if (!isDrom(countryCode)) {
            return (
                <ReactCountryFlag
                    countryCode={countryCode}
                    svg
                    key={countryCode}
                />
            );
        } else if (!hasDrom) {
            setHasDrom(true);
        }
    };

    return (
        <>
            {product.availableCountries &&
                !isEmpty(product.availableCountries) &&
                product.availableCountries.map(countryCode => displayFlag(countryCode))}

            {hasDrom && (
                <ReactCountryFlag
                    countryCode={''}
                    className={'drom'}
                />
            )}
        </>
    );
}
