import { useEffect, useState } from 'react';
import { useFetch } from '../Hooks/useFetch';
import { TaxonInterface } from '../Interfaces/Taxon';
import { taxonService } from '../Services/Services';

export interface Taxon {
    code: string | number;
    name: string;
    children?: Taxon[];
}

export interface TaxonResponse {
    taxons: Taxon[];
    title: string;
}

export function useFetchTaxons(taxonName: string): TaxonResponse {
    const { data: taxonsData } = useFetch<TaxonInterface | TaxonInterface[], [string]>({
        service: () => new taxonService().getTaxon(taxonName),
        cacheKey: `taxon_${taxonName}`,
        dependencies: [taxonName],
    });

    const [response, setResponse] = useState<TaxonResponse>({ taxons: [], title: '' });

    useEffect(() => {
        if (!taxonsData) return;

        const taxons = Array.isArray(taxonsData) ? taxonsData : [taxonsData];
        const title = taxons[0]?.name || '';

        const items: Taxon[] = [];
        taxons.forEach(taxon => {
            taxon.children?.forEach((child: TaxonInterface) => {
                if (child.name) {
                    items.push({ code: child.code, name: child.name });
                }
            });
        });

        setResponse({ taxons: items, title });
    }, [taxonsData]);

    return response;
}
