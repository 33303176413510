import classNames from 'classnames';
import { PRODUCT_TYPE_ECARD, PRODUCT_TYPE_PHYSICAL_CARD } from '../../../../Constant.tsx';
import { ProductInterface } from '../../../../Interfaces/Product.ts';
import { useSharedTranslation } from 'Translation/useSharedTranslation';

type DetailProps = {
    product: ProductInterface;
    isHidden: boolean;
};

export default function DetailTab({ product, isHidden }: DetailProps) {
    const { t } = useSharedTranslation();

    const displayProductValidity = () => {
        if (
            product.validityLength &&
            (product.productType?.name === PRODUCT_TYPE_ECARD || product.productType?.name === PRODUCT_TYPE_PHYSICAL_CARD)
        ) {
            return (
                <div className={'product-details'}>
                    <span className={'product-details-name'}>Durée de validité : </span>
                    <span className={'product-details-value'}>{product.validityLength} mois</span>
                </div>
            );
        }
    };

    const displayProductDivisible = () => {
        if (product.productType?.name === PRODUCT_TYPE_ECARD || product.productType?.name === PRODUCT_TYPE_PHYSICAL_CARD) {
            const isDivisible = product?.productDivisibleChoice?.isDivisible;

            return (
                <div className={'product-details'}>
                    <span className={'product-details-name'}>{t('product.products.cartdivisible')} : </span>
                    <span className={'product-details-value'}>{isDivisible ? t('common.common.yes') : t('common.common.no')}</span>
                </div>
            );
        }
    };

    const displayProductCumulative = () => {
        if (product.productType?.name === PRODUCT_TYPE_ECARD || product.productType?.name === PRODUCT_TYPE_PHYSICAL_CARD) {
            return (
                <div className={'product-details'}>
                    <span className={'product-details-name'}>{t('product.products.cartcumulative')} : </span>
                    {product.cumulative ?
                        <span className={'product-details-value'}>{t('common.common.yes')}</span>
                    :   <span className={'product-details-value'}>{t('common.common.no')}</span>}
                </div>
            );
        }
    };

    const displayProductDescription = () => {
        if (product.description) {
            return (
                <div
                    className={'product-description'}
                    dangerouslySetInnerHTML={{ __html: product.description }}
                />
            );
        }

        return <div className={'product-description'}>Aucune description.</div>;
    };

    return (
        <>
            <div className={classNames('tab-menu-content tab-content product-information', { 'is-hidden': isHidden })}>
                {displayProductValidity()}
                {displayProductDivisible()}
                {displayProductCumulative()}
                {displayProductDescription()}
            </div>
        </>
    );
}
